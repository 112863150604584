*{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

body {
  background: radial-gradient(#636669, #252627);
  background-repeat: no-repeat;
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: white;
}

/* Scroll bar */
::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  background: #888; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.container
{
  /* background: radial-gradient(#636669, #252627);
  background-repeat: no-repeat; */
  /* height: 100vh; */
  width: 100%;
}

/* Header Styles */
.header
{
  border-bottom: 1px dashed white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.header > h2
{
  margin-right: 20px;
}

.select-search-container
{
  width: 100%;
}
/* End Of Header Styles */


/* Body Styles */
.bodyContainer
{
  display: flex;
  flex-direction: column;
}
.toolbar__container
{
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed white;
}

.bodyToolbar
{
  display: flex;
  flex-direction: row;
}

.toolbar__button
{
  margin: 2.5px;
  padding: 5px;
  color: white;
  background-color: transparent;
  font-size: 18px;
  border-radius: 5px;
}

.toolbar__button__big
{
  margin: 2.5px;
  padding: 10px;
  color: white;
  background-color: transparent;
  font-size: 26px;
  border-radius: 5px;
}

.table__container
{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* block-size: 100vh;
  overflow: hidden; */
}
.fill-grid
{
  block-size: 100%;
  /* display: flex; */
}

.base-fill-grid
{
  block-size: 80%;
}
/* End of Body Styles */


/* Item Styles */
table, td, th {  
  border: 1px solid #ddd;
  text-align: left;
}

td, th {
  vertical-align: top; /* or top, bottom, baseline, etc. */
}

table {
  border-collapse: collapse;
  width: 100%;
}

.tax__table {
  width: 100%;
}

.cell-content {
  display: flex;
  align-items: flex-start; /* Align text at the top */
  height: 100%;
}

.newItemRow
{
  background-color: green;
}

.deletedItemRow
{
  background-color: #f50505;
}

.updatedItemRow
{
  background-color: #f5e505;
  color: black;
}

.summaryHeading
{
  margin: 5px;
  border: 1px dashed;
  padding: 5px;
}

#summaryContainer
{
  position: sticky;
  top: 0;
  background: radial-gradient(#636669, #252627);
  background-repeat: no-repeat;
}
/* End Of Item Styles */


/* loader */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.99);
  z-index: 9999;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.overlay__hidden
{
  display:none;
}
/* end of loader */




